import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'qs';
import { isNil, sortBy, toNumber } from 'lodash';
import { Page, VerticalContainer } from '../components/layout';
import { Input } from '../components/forms';
import Message from '../components/elements/Message';
import s from './App.module.scss';

const initQuery = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: parseInt(process.env.REQUEST_TIMEOUT, 10) || 10000,
  headers: { 'x-api-key': initQuery.apiKey },
});
axiosRetry(instance, { retries: 3 });

const DEFAULT_MAX_WAITING_ORDERS = 15;
const DEFAULT_MAX_WAITING_ORDERS_POLLING_INTERVAL_MS = 8000;
const ORDERING_TYPE_TIMEOUT = 30000;
const NUMBER_OF_INPUT_DIGITS = 6;

const DONALD_STATES = {
  READY_FOR_INPUT: 'readyForInput',
  VALIDATING_KEY: 'validatingKey',
  CONFIRM_KEY: 'confirmKey',
  ORDERING: 'ordering',
  IN_PRODUCTION: 'inProduction',
  ERROR: 'error',
};

const sleep = ms => new Promise(r => setTimeout(r, ms));

class App extends Component {
  state = {
    code: '',
    errorMessage: '',
    status: DONALD_STATES.READY_FOR_INPUT,
    orders: [],
    orderingType: 'defaultOrder',
  };

  orderingTypeTimeout = null;

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
    // refreshing every 45 minutes
    setInterval(() => {
      const { code } = this.state;
      if (!code) window.location.reload(true);
    }, 1000 * 60 * 45);

    this.checkMaxWaitingOrders();

    clearInterval(this.checkMaxWaitingOrdersIID);
    const pollIntervalInMs =
      parseInt(process.env.POLLING_INTERVAL, 10) ||
      DEFAULT_MAX_WAITING_ORDERS_POLLING_INTERVAL_MS;
    this.checkMaxWaitingOrdersIID = setInterval(
      this.checkMaxWaitingOrders,
      pollIntervalInMs,
    );
  }

  componentWillUnmount() {
    clearInterval(this.checkMaxWaitingOrdersIID);
  }

  handleKeyPress = ({ key }) => {
    const { code, status, orderingType } = this.state;

    console.info('KEY', { key, code, status });

    if (status === DONALD_STATES.READY_FOR_INPUT && ['.'].includes(key)) {
      if (orderingType === 'defaultOrder') {
        this.setState({ orderingType: 'redoOrder' });
        clearTimeout(this.orderingTypeTimeout);
        this.orderingTypeTimeout = setTimeout(
          this.resetOrderingType,
          ORDERING_TYPE_TIMEOUT,
        );
        return;
      }

      if (orderingType === 'redoOrder') {
        this.setState({ orderingType: 'bcOrder' });
        clearTimeout(this.orderingTypeTimeout);
        this.orderingTypeTimeout = setTimeout(
          this.resetOrderingType,
          ORDERING_TYPE_TIMEOUT,
        );
        return;
      }

      clearTimeout(this.orderingTypeTimeout);

      this.setState({ orderingType: 'defaultOrder' });
      return;
    }

    if (
      [DONALD_STATES.CONFIRM_KEY, DONALD_STATES.VALIDATING_KEY].includes(status)
    ) {
      if (key === 'Enter') {
        this.handleSubmit();
        return;
      }

      if (key === 'Backspace') {
        this.retry();
        return;
      }
    }

    if (status === DONALD_STATES.ERROR) {
      this.retry();
      return;
    }

    if (status === DONALD_STATES.READY_FOR_INPUT) {
      let newValue = `${code}${key}`;
      if (['Backspace', 'Delete'].includes(key)) {
        newValue = code.slice(0, code.length - 1);
      }

      if (key === 'Enter') {
        this.handleSubmit();
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(newValue)) return;

      const newCode = newValue.slice(0, NUMBER_OF_INPUT_DIGITS);
      this.setState({ code: newCode });

      // auto-submit if code is NUMBER_OF_INPUT_DIGITS digits long
      if (newCode.length === NUMBER_OF_INPUT_DIGITS) {
        this.handleSubmit();
      }
    }
  };

  checkMaxWaitingOrders = async () => {
    try {
      const waitingOrders = await instance.get(
        `/orders?$sort[priority]=-1&$sort[createdAt]=1&$limit=100&storeId=${
          initQuery.storeId
        }&$or[0][state]=waiting`,
      );
      const maxWaitingOrders =
        initQuery.maxWaitingOrders || DEFAULT_MAX_WAITING_ORDERS;

      console.info('waitingOrders', { waitingOrders, maxWaitingOrders });

      if (waitingOrders.status === 200 && waitingOrders.data.data) {
        const numberOfWaitingOrders = waitingOrders.data.data.length;

        const isFull = numberOfWaitingOrders >= maxWaitingOrders;
        if (isFull) {
          this.setState({
            maxWaitingOrders,
            status: DONALD_STATES.IN_PRODUCTION,
          });
          return;
        }

        const { status } = this.state;
        if (status === DONALD_STATES.IN_PRODUCTION) {
          this.retry();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  resetOrderingType = () => {
    this.setState({ orderingType: 'defaultOrder', code: '' });
  };

  loadOrdersWithBCOrderId = async (bcOrderId, noFilter = false) => {
    const result = await instance.get(
      `/orders?bcOrderId=${bcOrderId}&$limit=30`,
    );
    // console.info(result);

    const orders = result.data.data.filter(
      ({ state }) => noFilter || state === 'unconfirmed',
    );

    if (orders.length === 0) {
      throw new Error('No orders found!');
    }

    // console.info('loadOrdersWithBCOrderId', orders);

    await this.populateFragrancesForOrders(orders);

    const { status } = this.state;
    if (status === DONALD_STATES.VALIDATING_KEY) {
      this.setState({
        status: DONALD_STATES.CONFIRM_KEY,
      });
    }
  };

  loadSiblingOrdersFromOrder = async order => {
    const { userId, createdAt, storeId } = order;
    const hoursBack = 12;
    const backInTime = new Date(createdAt);
    backInTime.setTime(backInTime.getTime() - hoursBack * 60 * 60 * 1000);
    const result = await instance.get(
      `/orders?userId=${userId}&storeId=${storeId}&createdAt[$gt]=${backInTime.toISOString()}&state=unconfirmed&$limit=20&$sort[createdAt]=-1`,
    );
    // console.info(result);

    const orders = result.data.data.filter(
      ({ state }) => state === 'unconfirmed',
    );

    if (orders.length === 0) {
      throw new Error('No orders found!');
    }

    // console.info('loadSiblingOrdersFromOrder', orders);

    await this.populateFragrancesForOrders(orders);

    const { status } = this.state;
    if (status === DONALD_STATES.VALIDATING_KEY) {
      this.setState({
        status: DONALD_STATES.CONFIRM_KEY,
      });
    }
  };

  getOrder = async orderId => {
    try {
      const { data: order } = await instance.get(`/orders/${orderId}`);
      return order;
    } catch (error) {
      throw new Error('No orders found!');
    }
  };

  loadOrdersByCode = async orderId => {
    const order = await this.getOrder(orderId);
    // console.info('loadOrdersByCode', order);

    if (!isNil(order.bcOrderId)) {
      await this.loadOrdersWithBCOrderId(order.bcOrderId);
      return;
    }

    await this.loadSiblingOrdersFromOrder(order);
  };

  loadOrdersWithRedoOrderId = async orderId => {
    const result = await instance.get(`/orders?id=${orderId}`);
    // console.info(result);

    const orders = result.data.data;

    // console.info('loadOrdersWithRedoOrderId', orders);

    await this.populateFragrancesForOrders(orders);

    const { status } = this.state;
    if (status === DONALD_STATES.VALIDATING_KEY) {
      this.setState({
        status: DONALD_STATES.CONFIRM_KEY,
      });
    }
  };

  populateFragrancesForOrders = async orders => {
    if (orders.length === 0) {
      throw new Error('No orders found!');
    }

    this.setState({ orders });

    const newOrders = await Promise.all(
      orders.map(async order => {
        const { data: fragrance } = await instance.get(
          `/fragrances/${order.fragranceId}`,
        );

        return { ...order, fragrance };
      }),
    );

    this.setState({
      orders: newOrders,
    });
  };

  handleCode = async code => {
    this.setState({
      status: DONALD_STATES.VALIDATING_KEY,
    });

    const { orderingType } = this.state;

    try {
      switch (orderingType) {
        case 'bcOrder':
          await this.loadOrdersWithBCOrderId(code, true);
          break;

        case 'redoOrder':
          await this.loadOrdersWithRedoOrderId(code);
          break;

        default:
          await this.loadOrdersByCode(code);
          break;
      }
    } catch (err) {
      console.error(err.message);
      this.setState({
        status: DONALD_STATES.ERROR,
        errorMessage: err.message,
      });
    }
  };

  handleSubmit = async () => {
    const { code, status } = this.state;
    if (status === DONALD_STATES.IN_PRODUCTION) return;
    if (
      [DONALD_STATES.VALIDATING_KEY, DONALD_STATES.CONFIRM_KEY].includes(status)
    ) {
      this.order();
      return;
    }
    if (status === DONALD_STATES.ERROR) {
      this.retry();
      return;
    }

    this.setState({
      status: DONALD_STATES.VALIDATING_KEY,
      orders: [],
    });

    await this.handleCode(code);

    // after handling code, check for waiting orders and clear/re-enable interval
    this.checkMaxWaitingOrders();

    clearInterval(this.checkMaxWaitingOrdersIID);
    const pollIntervalInMs =
      parseInt(process.env.POLLING_INTERVAL, 10) ||
      DEFAULT_MAX_WAITING_ORDERS_POLLING_INTERVAL_MS;
    this.checkMaxWaitingOrdersIID = setInterval(
      this.checkMaxWaitingOrders,
      pollIntervalInMs,
    );
  };

  retry = () => {
    this.setState({
      status: DONALD_STATES.READY_FOR_INPUT,
      orders: [],
      code: '',
      errorMessage: '',
    });
  };

  order = async () => {
    const { orderingType, orders } = this.state;
    if (orders.length === 0) {
      console.info('Trying to order with no found orders');
      return;
    }
    this.setState({
      status: DONALD_STATES.ORDERING,
    });
    try {
      await Promise.all(
        orders.map(async ({ id }) => {
          const priority = orderingType === 'redoOrder' ? 1 : undefined;
          console.info('patch', id, priority);

          await instance.patch(`/orders/${id}`, {
            state: 'waiting',
            metadata: 'By Donald',
            storeId: toNumber(initQuery.storeId),
            priority,
          });
        }),
      );

      await sleep(3000);

      // Check if orders has been set properly

      this.setState({
        code: '',
        orderingType: 'defaultOrder',
        status: DONALD_STATES.READY_FOR_INPUT,
        orders: [],
      });
    } catch (err) {
      console.error(err.message);
      this.setState({
        status: DONALD_STATES.ERROR,
        errorMessage: `Couldn't send order to machine`,
      });
    }
  };

  getTopText = () => {
    const { orderingType, status, orders } = this.state;
    switch (status) {
      case DONALD_STATES.READY_FOR_INPUT: {
        switch (orderingType) {
          case 'bcOrder':
            return '## BC ORDER ##';
          case 'redoOrder':
            return '>> REDO ORDER <<';
          default:
            return 'Please enter production code';
        }
      }

      case DONALD_STATES.VALIDATING_KEY:
        return 'Bear with me, loading...';
      case DONALD_STATES.CONFIRM_KEY:
        return orders.length > 1
          ? 'Are these your fragrances?'
          : 'Is this your fragrance?';
      case DONALD_STATES.ORDERING:
        return 'Sending to the machine...';
      case DONALD_STATES.IN_PRODUCTION:
        return `Bear with me, I need some ♥`;
      case DONALD_STATES.ERROR:
        return 'Whoops...';

      default:
        return '';
    }
  };

  render() {
    const {
      status,
      code,
      orderingType,
      orders,
      errorMessage,
      maxWaitingOrders,
    } = this.state;
    const transitionClassNames = {
      enter: s.enter,
      enterActive: s.enterActive,
      exit: s.exit,
      exitActive: s.exitActive,
      exitDone: s.exitDone,
    };

    return (
      <VerticalContainer>
        <Page
          specialMode={orderingType !== 'defaultOrder'}
          className={s.page}
          title={this.getTopText()}
        >
          {/* ----------- IN_PRODUCTION ----------- */}
          <CSSTransition
            classNames={transitionClassNames}
            in={status === DONALD_STATES.IN_PRODUCTION}
            timeout={300}
            unmountOnExit
          >
            <div>
              <Message
                hide={false}
              >{`Please wait until the waiting queue is reduced (max ${maxWaitingOrders} reached)`}</Message>
            </div>
          </CSSTransition>

          {/* ----------- READY_FOR_INPUT ----------- */}
          <CSSTransition
            classNames={transitionClassNames}
            in={status === DONALD_STATES.READY_FOR_INPUT}
            timeout={300}
            unmountOnExit
          >
            <div>
              <Input.KeyBox
                onChange={this.handleKeyPress}
                onSubmit={this.handleSubmit}
                value={code}
                placeholder="Type here"
              />
              <Message
                hide={orderingType === 'defaultOrder' && code.length <= 5}
              >
                Press Enter to confirm
              </Message>
            </div>
          </CSSTransition>

          {/* ----------- VALIDATING_KEY ----------- */}
          <CSSTransition
            classNames={transitionClassNames}
            in={[
              DONALD_STATES.VALIDATING_KEY,
              DONALD_STATES.CONFIRM_KEY,
              DONALD_STATES.ORDERING,
            ].includes(status)}
            timeout={300}
            unmountOnExit
          >
            <div id="orders-container" className={s.data}>
              {orders &&
                orders.length > 0 &&
                !isNil(orders[0].bcOrderId) && (
                  <div>
                    <span>
                      <b className={s.bcOrderId}>#{orders[0].bcOrderId}</b>
                    </span>
                  </div>
                )}
              {orders &&
                orders.length > 0 &&
                sortBy(orders, 'id').map(o => (
                  <div key={`order-${o.id}`}>
                    <span>
                      <b className={s.orderId} key={`order-b-${o.id}`}>
                        {o.id}
                      </b>{' '}
                    </span>
                    <span key={`order-title-${o.id}`}>
                      {!isNil(o.fragrance) && o.fragrance.title}
                    </span>
                  </div>
                ))}
              <Message
                hide={orders.length === 0 || status === DONALD_STATES.ORDERING}
              >
                Press Enter ↵ to confirm
              </Message>
            </div>
          </CSSTransition>
          {/* ----------- ERROR ----------- */}
          <CSSTransition
            classNames={transitionClassNames}
            in={status === DONALD_STATES.ERROR}
            timeout={300}
            unmountOnExit
          >
            <Message hide={false}>
              {`${errorMessage} - Press Enter ↵ to retry`}
            </Message>
          </CSSTransition>
        </Page>
      </VerticalContainer>
    );
  }
}
export default App;
